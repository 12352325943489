<template>
  <ul class="pagination justify-content-center">
    <li class="page-item" :class="{ disabled: !pagination.hasPre }">
      <button
        type="button"
        class="page-link"
        aria-label="Previous"
        :disabled="!pagination.hasPre"
        @click="chagePage(pagination.currentPage - 1)"
      >
        <span aria-hidden="true">&laquo;</span>
      </button>
    </li>
    <template v-for="page in pagination.totalPages" :key="page">
      <li
        class="page-item"
        :class="{ active: page === pagination.currentPage }"
        v-if="
          page >= pagination.currentPage - 2 &&
          page <= pagination.currentPage + 2
        "
      >
        <button
          type="button"
          class="page-link text-dark"
          @click="chagePage(page)"
        >
          {{ page }}
        </button>
      </li>
    </template>

    <li class="page-item" :class="{ disabled: !pagination.hasNext }">
      <button
        type="button"
        class="page-link"
        aria-label="Next"
        :disabled="!pagination.hasNext"
        @click="chagePage(pagination.currentPage + 1)"
      >
        <span aria-hidden="true">&raquo;</span>
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  name: "pagination",
  props: ["pagination"],
  setup(props, { emit }) {
    const chagePage = (page) => {
      emit("changePage", page);
    };
    return {
      chagePage,
    };
  },
};
</script>
