<template>
  <label class="inputFile mr-3" :for="`image${data.index}`">
    <span class="inputFile-text">{{ data.path || "未上傳檔案" }}</span>
  </label>
  <input
    type="file"
    :id="`image${data.index}`"
    hidden
    class="inputFile-file"
    ref="fileInput"
    @change="upload()"
  />
</template>

<script>
import { ref, toRef, inject } from "vue";
import { uploadFile, removeFile } from "connection/admin/firebaseStorage";
export default {
  name: "upload",
  emits: ["updateFile"],
  props: ["data"],
  setup(props, { emit }) {
    //id index path
    const data = toRef(props, "data");
    const mitt = inject("mitt");
    const fileInput = ref(null);
    const { id, path, index } = data.value;

    // ======== upload ========= //
    const upload = async () => {
      //刪除
      if (path) {
        removeFile(data.value);
      }
      //新增
      const file = fileInput.value.files[0];
      const newPath = file.name;
      try {
        let url = await uploadFile({ id, path: newPath }, file);
        mitt.emit("alert", { state: "success", message: "圖片上傳成功" });
        emit("updateFile", { index, url, path: newPath });
      } catch (error) {
        mitt.emit("alert", { state: "danger", message: error.message });
      }
    };
    return {
      fileInput,
      upload,
    };
  },
};
</script>
