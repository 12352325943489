<template>
  <div class="pt-5 text-center">
    <h2 class="h2">產品列表</h2>
    <section class="text-right px-5">
      <div class="d-flex justify-content-between">
        <div class="d-flex">
          <input
            type="text"
            class="px-3"
            placeholder="請輸入 商品型號 或 商品名稱"
            v-model.trim="searchNumber"
            @input="resetCurrentPage"
          />
          <div class="form-inline ml-3">
            <select
              v-model="selectCategory"
              class="form-select"
              @change="resetCurrentPage"
            >
              <option value="" selected hidden disabled>類別選擇</option>
              <template v-for="item in categories" :key="item.id">
                <option :value="item.name">{{ item.name }}</option>
              </template>
            </select>
          </div>
        </div>

        <button class="btn btn-primary ml-auto" @click="openModal('new')">
          新增產品
        </button>
      </div>

      <div class="table-responsive mt-3">
        <table class="table table-hover table-striped">
          <thead class="thead-dark">
            <tr class="text-center">
              <th width="200">型號</th>
              <th width="120">副型號</th>
              <th>名稱</th>
              <th width="200">類別</th>
              <th width="120">廠牌</th>
              <th width="100">上架</th>
              <th width="200">建立日期</th>
              <th width="200" class="text-center">操作</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="item in searchData.products" :key="item.id">
              <tr class="text-center">
                <td class="align-middle" width="200">{{ item.number }}</td>
                <td class="align-middle" width="120">
                  <template v-for="shape in item.shape" :key="shape.id">
                    <h6>{{ shape.subNumber || "無" }}</h6>
                  </template>
                </td>
                <td class="align-middle">
                  <template v-for="shape in item.shape" :key="shape.title">
                    <h6 class="h6 mb-0">{{ shape.title }}</h6>
                  </template>
                </td>
                <td class="align-middle" width="200">{{ item.category }}</td>
                <td class="align-middle" width="120">{{ item.brand }}</td>

                <td class="align-middle" width="100">
                  <template v-for="shape in item.shape" :key="shape.title">
                    <h6 class="text-success h6 mb-0" v-if="shape.isSell">
                      上架中
                    </h6>
                    <h6 class="text-danger h6 mb-0" v-else>下架</h6>
                  </template>
                </td>
                <td width="100" class="align-middle">
                  {{ formatDate(item.createTime) }}
                </td>
                <td width="200" class="align-middle">
                  <div class="btn-group w-100">
                    <button
                      class="btn btn-outline-danger"
                      @click="openModal('edit', item)"
                    >
                      編輯
                    </button>
                    <button
                      class="btn btn-outline-danger"
                      @click="openModal('remove', item)"
                    >
                      刪除
                    </button>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <Pagination
        :pagination="searchData.pagination"
        @changePage="changePage"
      />
    </section>

    <!-- create -->
    <FormModal @submit="updateProductData" @close="formatTemp">
      <!-- title -->
      <template v-slot:title>
        <span v-if="modalType === 'new'">新增</span>
        <span v-if="modalType === 'edit'">編輯</span>
        <span v-if="modalType === 'remove'">刪除</span>
      </template>
      <!-- body -->
      <template v-slot:body>
        <form
          v-if="modalType === 'new' || modalType === 'edit'"
          @submit="onSubmit"
        >
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-xl-2">
                <div class="form-group mt-3 text-left">
                  <label for="number" class="mb-2">編號</label>
                  <input
                    type="text"
                    id="number"
                    class="form-control"
                    placeholder="請輸入產品型號"
                    v-model="temp.number"
                  />
                </div>
                <!-- category -->
                <div class="form-group text-left">
                  <label for="category" class="mb-2">類別</label>
                  <select class="form-control" v-model="temp.category">
                    <option value="" selected hidden disabled>
                      請選擇類別
                    </option>
                    <template v-for="item in categories" :key="item.id">
                      <option :value="item.name">
                        {{ item.name }}
                      </option>
                    </template>
                  </select>
                </div>
                <!-- brand -->
                <div class="form-group text-left">
                  <label for="category" class="mb-2">廠牌</label>
                  <select class="form-control" v-model="temp.brand">
                    <option value="" selected hidden disabled>
                      請選擇廠牌
                    </option>
                    <template v-for="item in allBrand" :key="item.id">
                      <option :value="item.name">{{ item.name }}</option>
                    </template>
                  </select>
                </div>
              </div>
              <div class="col-12 col-xl-10">
                <div class="form-group">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <label for="image">商品名稱</label>
                    <button
                      type="button"
                      class="btn"
                      @click="
                        temp.shape.push({
                          id: Math.floor(new Date()),
                          isSell: false,
                        })
                      "
                    >
                      <i class="fas fa-plus"></i>
                    </button>
                  </div>

                  <template v-for="(item, itemIndex) in temp.shape" :key="item">
                    <div class="row mb-1 bg-primary py-3">
                      <div class="col-6 col-lg-2">
                        <input
                          type="text"
                          class="form-control"
                          v-model="item.subNumber"
                          placeholder="副代碼"
                        />
                      </div>
                      <div class="col-6 col-lg-3">
                        <input
                          type="text"
                          placeholder="名稱"
                          v-model="item.title"
                          class="form-control"
                        />
                      </div>
                      <div class="col-6 col-lg-3">
                        <UploadFile
                          :data="{
                            index: itemIndex,
                            id: item.id,
                            path: item.path,
                          }"
                          @updateFile="updateFile"
                        />
                      </div>
                      <div class="col-6 col-lg-2">
                        <input
                          type="text"
                          placeholder="備註"
                          class="form-control"
                          v-model="item.mark"
                        />
                      </div>
                      <div class="col-12 col-lg-2 mt-3 mt-lg-0">
                        <div class="form-inline ml-auto justify-content-end">
                          <div class="form-check">
                            <input
                              type="checkbox"
                              name="isSell"
                              :id="`sell${itemIndex}`"
                              checked
                              class="form-check-input"
                              v-model="item.isSell"
                            />
                            <label
                              :for="`sell${itemIndex}`"
                              class="form-check-label"
                              >上架中</label
                            >
                          </div>
                          <button
                            type="button"
                            class="btn btn-sm text-danger"
                            @click="removeShape(item.id, index, item.path)"
                          >
                            <i class="fas fa-times"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </form>
      </template>
    </FormModal>

    <!-- removeModal -->
    <RemoveModal @submit="removeProductData">
      <template v-slot:title> 刪除:{{ temp.number }} </template>
      <template v-slot:body>
        <div class="text-left">
          編號: {{ temp.number }}
          <br />
          分類: {{ temp.category }}
          <br />
          廠牌: {{ temp.brand }}
          <br />
          產品:
          <ul>
            <li v-for="item in temp.shape" :key="item.id">
              {{ item.title }}
            </li>
          </ul>
        </div>
      </template>
    </RemoveModal>
  </div>
</template>

<script>
import $ from "jquery";
import FormModal from "components/back/FormModal";
import RemoveModal from "components/back/Modal";
import { computed, onMounted, ref, inject } from "vue";
import { fetchCategories } from "connection/admin/categoryDB";
import {
  fetchProducts,
  createProduct,
  editProduct,
  removeProduct,
} from "connection/admin/productDB";
import { fetchBrand } from "connection/admin/brandDB";
import { removeFile } from "connection/admin/firebaseStorage";
import Pagination from "components/common/Pagination";
import UploadFile from "components/back/UploadFile";
import filter from "helpers/filterData";

export default {
  name: "production",
  components: {
    FormModal,
    Pagination,
    UploadFile,
    RemoveModal,
  },
  setup() {
    //init
    const mitt = inject("mitt");
    const products = ref([]);
    const categories = ref([]);
    const temp = ref({
      number: "",
      brand: "",
      category: "",
      shape: new Array({
        id: Math.floor(new Date()),
        subNumber: "",
        title: "",
        isSell: false,
        mark: "",
      }),
    });
    //category
    const getCategories = async () => {
      try {
        categories.value = await fetchCategories();
      } catch (error) {
        mitt.emit("alert", { state: "danger", message: error.message });
      }
    };
    //product
    const getProducts = async () => {
      try {
        mitt.emit("loading", true);
        products.value = await fetchProducts();
        mitt.emit("loading", false);
      } catch (error) {
        mitt.emit("alert", { state: "danger", message: error.message });
      }
    };
    //brand
    const allBrand = ref([]);
    const getBrand = async () => {
      try {
        allBrand.value = await fetchBrand();
      } catch (error) {
        console.log(error);
      }
    };
    onMounted(() => {
      getCategories();
      getProducts();
      getBrand();
    });

    //新增
    const createItem = async () => {
      try {
        removeImage();
        await createProduct(temp.value);
        mitt.emit("alert", { state: "success", message: "產品新增成功" });
        getProducts();
      } catch (error) {
        mitt.emit("alert", { state: "danger", message: error.message });
      }
    };
    //修改
    let tempShape = []; //紀錄要刪除的shape圖片位置
    const editItem = async () => {
      try {
        //判斷是否要刪除圖片
        removeImage();
        await editProduct(temp.value);
        mitt.emit("alert", { state: "success", message: "產品已修改" });
        getProducts();
      } catch (error) {
        mitt.emit("alert", { state: "danger", message: error.message });
      }
    };
    //刪除
    const removeItem = async () => {
      try {
        //刪除照片
        temp.value.shape.forEach((shape) => {
          if (shape.path) {
            removeFile(shape);
          }
        });
        await removeProduct(temp.value.id);
        getProducts();
        mitt.emit("alert", { state: "success", message: "產品已刪除" });
      } catch (error) {
        mitt.emit("alert", { state: "danger", message: error.message });
      }
    };
    //modal

    const modalType = ref("");
    const openModal = (type, item) => {
      modalType.value = type;
      if (type === "new") {
        //new
        Object.assign(temp.value, {});
      } else {
        Object.assign(temp.value, item);
      }

      if (type === "new" || type === "edit") {
        $("#FormModal").modal("show");
      } else {
        $("#Modal").modal("show");
      }
    };

    const updateProductData = () => {
      if (modalType.value === "new") {
        createItem();
      } else {
        editItem();
      }
      formatTemp();
      $("#FormModal").modal("hide");
    };

    const removeProductData = () => {
      try {
        removeItem();
        $("#Modal").modal("hide");
      } catch (error) {
        console.log(error);
      }
    };

    //============ remove shape =================//
    const removeImage = () => {
      tempShape.forEach((shape) => {
        if (shape.path) {
          removeFile(shape);
        }
      });
      tempShape = [];
    };
    const removeShape = async (id, index, path) => {
      //放入暫存刪除
      tempShape.push({
        id,
        index,
        path,
      });
      const shapeIndex = temp.value.shape.findIndex((item) => item.id === id);
      if (shapeIndex !== -1) {
        temp.value.shape.splice(shapeIndex, 1);
      }
    };
    //==========        updateFile     =============//
    const updateFile = (data) => {
      const { index, url, path } = data;
      temp.value.shape[index].imgUrl = url;
      temp.value.shape[index].path = path;
    };
    //pagination
    const changePage = (page) => {
      currentPage.value = page;
    };
    //date
    const formatDate = computed(() => {
      return function (timestamp) {
        return new Date(timestamp).toISOString().split("T")[0];
      };
    });
    const formatTemp = () => {
      temp.value = {
        number: "",
        category: "",
        brand: "",
        shape: new Array({
          id: Math.floor(new Date()),
          subNumber: "",
          title: "",
          isSell: false,
          mark: "",
        }),
      };
    };

    //=========     search     ========//
    const selectCategory = ref("");
    const searchNumber = ref("");
    let currentPage = ref(1);
    const resetCurrentPage = () => {
      currentPage.value = 1;
    };

    const searchData = computed(() => {
      let sourceData;
      if (!searchNumber.value) {
        sourceData = products.value;
      } else {
        sourceData = products.value.filter((item) => {
          if (
            item.number.match(searchNumber.value.toUpperCase()) ||
            item.shape.filter((shape) => shape.title.match(searchNumber.value))
              .length > 0
          )
            return item;
        });
      }

      if (selectCategory.value) {
        sourceData = sourceData.filter((item) =>
          item.category.match(selectCategory.value)
        );
      }
      let { data, pageData } = filter(sourceData, currentPage.value);
      return {
        products: data,
        pagination: pageData,
      };
    });

    return {
      temp,
      products,
      categories,
      allBrand,
      openModal,
      createItem,
      modalType,
      updateProductData,
      removeProductData,
      changePage,
      formatDate,
      updateFile,
      formatTemp,
      removeShape,
      //search
      selectCategory,
      searchNumber,
      searchData,
      resetCurrentPage,
    };
  },
};
</script>
