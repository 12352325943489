<template>
  <div
    class="modal fade"
    id="FormModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <slot name="title"></slot>
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <slot name="body" />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            @click="closeModal"
          >
            關閉
          </button>
          <button type="button" class="btn btn-primary" @click="submit()">
            儲存
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "formModal",
  props: ["changeSize"],
  setup(props, { emit }) {
    const close = () => {
      emit("close");
    };
    const submit = () => {
      emit("submit");
    };
    return {
      close,
      submit,
    };
  },
};
</script>
