import "../noAuth/firebase";

import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
const storage = getStorage();

async function uploadFile(data, file) {
  const { id, path } = data;
  const storageRef = ref(storage, `images/${id}/${path}`);
  let itemRef = await uploadBytes(storageRef, file);
  return getDownloadURL(itemRef.ref);
}

function removeFile(data) {
  let { id, path } = data;
  const storageRef = ref(storage, `images/${id}/${path}`);
  return deleteObject(storageRef);
}
export { uploadFile, removeFile };
