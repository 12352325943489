export default (array, currentPage = 1) => {
  const perPageItems = 9;
  const totalPages = Math.ceil(array.length / perPageItems);
  const minIndex = (currentPage - 1) * perPageItems;
  const maxIndex = currentPage * perPageItems - 1;

  let data = array.filter((item, index) => {
    if (index >= minIndex && index <= maxIndex) {
      return item;
    }
  });

  return {
    data,
    pageData: {
      currentPage,
      totalPages,
      hasPre: currentPage > 1,
      hasNext: currentPage < totalPages,
    },
  };
};
