import "../noAuth/firebase";
import {
  getFirestore,
  collection,
  doc,
  setDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  query,
  orderBy,
} from "firebase/firestore";

const db = getFirestore();

async function fetchProducts() {
  const productionCollection = collection(db, "productions");
  const q = query(productionCollection, orderBy("createTime"));
  let newArray = [];
  let snapshot = await getDocs(q);
  snapshot.forEach((item) => {
    newArray.push(item.data());
  });
  return newArray.reverse();
}

async function createProduct(item) {
  const productionCollection = collection(db, "productions");
  const prdouctionDoc = doc(productionCollection);
  return await setDoc(prdouctionDoc, {
    id: prdouctionDoc.id,
    number: item.number,
    category: item.category,
    brand: item.brand,
    createTime: Math.floor(new Date()),
    visibility:
      item.shape.filter((item) => item.isSell).length > 0 ? true : false,
    shape: item.shape,
  });
}

async function editProduct(item) {
  const { id, number, category, brand, shape } = item;
  const refDoc = doc(db, "productions", id);
  return await updateDoc(refDoc, {
    id,
    number,
    category,
    brand,
    createTime: Math.floor(new Date()),
    visibility:
      item.shape.filter((item) => item.isSell).length > 0 ? true : false,
    shape,
  });
}

async function removeProduct(id) {
  return await deleteDoc(doc(db, "productions", id));
}

export { fetchProducts, createProduct, editProduct, removeProduct };
