import "../noAuth/firebase";
import {
  getFirestore,
  collection,
  doc,
  setDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  orderBy,
} from "firebase/firestore";

const db = getFirestore();
const brandsRef = collection(db, "brands");

async function fetchBrand() {
  let newArray = [];
  let snapshot = await getDocs(brandsRef, orderBy("createTime", "desc"));
  snapshot.forEach((item) => {
    newArray.push(item.data());
  });
  return newArray;
}

function create(name, path) {
  const newRef = doc(brandsRef);
  return setDoc(newRef, {
    id: newRef.id,
    name,
    path,
    createTime: Math.floor(new Date()),
  });
}

function edit(item) {
  const { id, name, path } = item;
  const refDoc = doc(db, "brands", id);
  return updateDoc(refDoc, {
    name,
    path,
    createTime: Math.floor(new Date()),
  });
}
function remove(id) {
  const refDoc = doc(db, "brands", id);
  return deleteDoc(refDoc);
}

export { fetchBrand, create, edit, remove };
